import { HTTP } from './http'

export const KeywordMonitoring = {
	async fetchCompetitors(fbo_sku, query=null){
		return await HTTP.get(
			`/keyword_monitoring/ozon/competitors/${fbo_sku}/`, { params: query }
		)
	},
	async fetchDetailCompetitors(fbo_sku, query=null){
		return await HTTP.post(
			`/keyword_monitoring/ozon/competitors/${fbo_sku}/detail/`, query
		)
	},
	async getKeywordsMonitoringByDate (sku, query=null) {
		return await HTTP.get(
			`/keyword_monitoring/ozon/keywords/${sku}/by_date/`,
			{ params: query }
		)
	},
	async deleteOzonKeywords (mid, text) {
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${mid}/delete_keyword/`,
			{"text": text}
		)
	},
	async deleteOzonCompetitors (mid, sku) {
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${mid}/delete_competitors/`,
			{"sku": sku}
		)
	},
	async updateOzonKeywords (mid, text) {
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${mid}/update_keywords/`,
			{"text": text}
		)
	},
	async saveCompetitors (mid, competitors){
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${mid}/update_competitors/`,
			{"competitors": competitors}
		)
	},
	async fetchListCompetitors (mid){
		return await HTTP.get(`/keyword_monitoring/ozon/actions/${mid}/list_competitors/`)
	},
	async updateOzonFavoriteKeyword (mid, text, favorite) {
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${mid}/change_favorite_keyword/`,
			{"text": text, "favorite": favorite}
		)
	},
	async copyOzonKeyword (to_product, from_product) {
		return await HTTP.post(
			`/keyword_monitoring/ozon/actions/${to_product}/copy_keyword/`,
			{"from_product": from_product}
		)
	},
	async getKeywordsSellerByNmid(nmid){
		return await HTTP.get(`/keyword_monitoring/keywords_seller/${nmid}`)
	}
}