<template>
	<v-card class="mt-5">
		<v-card-title>Планирование поставок(создание расчетов)</v-card-title>
		<v-card-text>
			<v-row class="mt-5">
				<v-col>
					<v-autocomplete
						outlined clearable label="Выбор аккаунта" v-model="filters.account"
						:items="choices.account_list" item-text="name" item-value="id" 
						hide-details dense @change="fetch_products"
					></v-autocomplete>
				</v-col>
				<v-col class="d-flex">
					<v-menu 
						v-model="datepicker_menu" :close-on-content-click="false" 
						:nudge-right="20" transition="scale-transition" offset-y="offset-y" 
						min-width="290px" ref="datepicker_menu"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
								clearable v-model="dataRangeText" label="Период" readonly="readonly"
								v-bind="attrs" hide-details="auto" outlined dense v-on="on"
							></v-text-field>
						</template>
						<v-date-picker v-model="filters.dates_range" range :first-day-of-week="1"></v-date-picker>
					</v-menu>
				</v-col>
				<v-col>
					<v-menu offset-y>
						<template v-slot:activator="{ on }">
							<v-btn 
								depressed color="purple darken-2" dark v-on="on"
							>Выбрать фиксированный период</v-btn>
						</template>
						<v-list>
							<v-list-item
								v-for="(item, index) in [7, 14, 30]" :key="index" 
								class="list_item_period" @click="set_fixed_period(item)"
							><v-list-item-title>{{ item }} дней</v-list-item-title></v-list-item>
						</v-list>
					</v-menu>
				</v-col>
				<v-col>
					<v-btn 
						depressed color="purple darken-2" dark @click="create_supply_planning(false)"
						:loading="loading.create"
					>Спланировать поставку</v-btn>	
					<v-btn 
						depressed color="purple darken-2 mt-3" dark :loading="loading.create"
						@click="create_supply_planning(true)"
					>Спланировать поставку(ускоренно)</v-btn>	
					<p class="mt-3">
						До нажатия, загрузите файл с неактивными товарами. 
						Или выберите их в таблице
					</p>		
				</v-col>
			</v-row>
			<v-row class="mt-5">
				<v-col>
					<v-btn 
						depressed color="purple darken-2" dark @click="fetch_products_xlsx"
						:loading="products.loading_xlsx"
					>Выгрузить список товаров</v-btn>
				</v-col>
				<v-col>
					<div class="d-flex align-start justify-start">
						<v-file-input
							label="File input" outlined dense id="products_xlsx" hide-details
							style="width: 250px;"
						></v-file-input>
					</div>
				</v-col>
				<v-col>
					<div class="ml-5 d-flex align-start justify-start">
						<v-btn 
							depressed color="purple darken-2" dark @click="save_inactive_product"
							:loading="loading.save_inactive_product"
						>Сохранить неакивные товары</v-btn>
					</div>
				</v-col>
				<v-col>
					<v-select
						v-model="limit_turnover" item-text="name" item-value="value"
						:items="[
							{'value': 0, 'name': 'Без ограничения'},
							{'value': 7, 'name': '7 дней'}, 
							{'value': 14, 'name': '14 дней'}, 
							{'value': 30, 'name': '30 дней'}, 
						]"
						label="Ограничение оборачиваемости" clearable outlined dense
					></v-select>
				</v-col>
				<v-col>
					<div class="ml-5 d-flex align-start justify-start">
						<v-btn 
							depressed color="purple darken-2" dark @click="save_limit_turnover"
							:loading="loading.limit_turnover"
						>Сохранить оборачиваемость</v-btn>
					</div>
				</v-col>
				

			</v-row>
			<div class="mt-5">
				<v-data-table
					v-model="products.selected_items"
					:headers="products.headers" :items="products.data" item-key="nmid"
					show-select class="small-font elevation-0 mt-5 vertical-border"
					:items-per-page="-1" :footer-props="{'items-per-page-options': [25, 50, 100, -1]}"
					:loading="products.loading" fixed-header
				>
					<template v-slot:item.image="{ item }">
						<img style="max-height:60px" :src="item.image" alt="">
					</template>
				</v-data-table>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { General } from '@/api/general'
import {Suppl} from '@/api/suppl';

export default {
    name: 'SupplyPlanningCreate',
	data() {
		return {
			limit_turnover: null,
			filters: {
				account: null,
				dates_range: [],
			},
			datepicker_menu: false,
			loading: {
				account_list: false,
				create: false,
				save_inactive_product: false,
				limit_turnover: false
			},
			choices: {
				account_list: [],
			},
			products:{
				headers: [
					{ text: "Изображение", value: "image", sortable: false },
					{ text: "Артикул МП",  value: "nmid", sortable: true },
					{ text: 'Артикул 1С', value: 'code_1c', sortable: true },
					{ text: "Название", value: "name", sortable: true },
					{ text: "Штрихкод", value: "barcode", sortable: true },
					{ text: 'Бренд', value: 'brand', sortable: true },
					{ text: 'Статус', value: 'status', sortable: true },
					{ text: 'Заказы 30 дней Р', value: 'dynamic_fields.orders_30days', sortable: false },
				],
				data: [],
				loading: false,
				selected_items: [],
				loading_xlsx: false
			}
		}
	},
	computed: {
		dataRangeText() {
			return this.filters.dates_range.join(' ~ ');
		},
		selectedNmids(){
			let result = []
			this.products.selected_items.forEach(item => {
				result.push(item.nmid)
			})
			return result
		},
		selectedNmidsForSave(){
			let result = []
			this.products.selected_items.forEach(item => {
				result.push({"code_mp": item.nmid, "code_1c": item.code_1c})
			})
			return result
		}
	},
	methods: {
		async save_inactive_product(){
			if (!this.filters.account){
				this.$toasted.show(`Ошибка(не выбран аккаунт)`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
				});
				return
			}
			this.loading.save_inactive_product = true
			let fileToUpload = document.getElementById('products_xlsx').files[0]
			let formData = new FormData()
			if (fileToUpload)
				formData.append('fileToUpload', fileToUpload)
			else
				formData.append('inactive_products', this.selectedNmidsForSave)
			formData.append('account', this.filters.account)
			try{
				await Suppl.saveInactiveProduct(formData)
				this.$toasted.show(`Сохранено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка сохранения`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.save_inactive_product = false
		},
		async save_limit_turnover(){
			if (!this.filters.account){
				this.$toasted.show(`Ошибка(не выбран аккаунт)`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000
				});
				return
			}
			this.loading.limit_turnover = true
			try{
				await Suppl.saveLimitTurnover({'limit_turnover': this.limit_turnover, 'account': this.filters.account})
				this.$toasted.show(`Сохранено`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				});
			} catch(err){
				this.$toasted.show(`Ошибка сохранения`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.limit_turnover = false
		},
		async fetch_wb_accounts () {
			const response = await General.getWbAccounts()
			this.choices.account_list = response.data
		},
		async fetch_products(){
			this.products.data = []
			this.products.selected_items = []
			this.products.loading = true
			let json_data = {"account": this.filters.account}
			try{
				const response = await Suppl.listProductsByAccount(json_data)
				this.products.data = response.data
			} catch(err){
				console.log(err)
			}
			this.products.loading = false
		},
		async fetch_products_xlsx(){
			if (!this.filters.account){
				this.$toasted.show(`Выберите аккаунт`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
				return
			}
			this.products.loading_xlsx = true
			let json_data = {"account": this.filters.account}
            try {
                const response = await Suppl.exportListProductsByAccount(json_data)
                const url = URL.createObjectURL(response.data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'products.xlsx')
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (err) {
                console.log(err.data)
            }
			this.products.loading_xlsx = false
		},
		async create_supply_planning(is_fast){
			if (!this.filters.account || !this.filters.dates_range || this.filters.dates_range.length < 2){
				this.$toasted.show(`Ошибка(заполните аккаунт, даты)`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
				return
			}
			this.loading.create = true
			let fileToUpload = document.getElementById('products_xlsx').files[0]
			let formData = new FormData()
			if (fileToUpload)
				formData.append('fileToUpload', fileToUpload)
			else
				formData.append('inactive_products', this.selectedNmids)
			formData.append('date_from', this.filters.dates_range[0])
			formData.append('date_to', this.filters.dates_range[1])
			formData.append('account', this.filters.account)
			if (this.limit_turnover != null)
				formData.append('limit_turnover', this.limit_turnover)
			if (is_fast){
				formData.append('is_fast', true)
			}
			try{
				await Suppl.createSupplyPlanning(formData)
				this.$router.push({name: 'supply_planning_list'})
			} catch(err){
				this.$toasted.show(`Ошибка создания`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
			}
			this.loading.create = false
		},
		set_fixed_period(period){
			let end_date = new Date().toISOString().slice(0,10)
			end_date = new Date(new Date(end_date)
				.setDate(new Date(end_date)
				.getDate() - 1))
				.toISOString()
				.slice(0, 10)
			let start_date = new Date(new Date(end_date)
				.setDate(new Date(end_date)
				.getDate() - period))
				.toISOString()
				.slice(0, 10)
			this.filters.dates_range = [start_date, end_date]
		}
	},
	async mounted(){
		document.title = 'Планирование поставок | mp.lomex-group.ru';
		this.$store.commit('setPageTitle', `Планирование поставок`)
		await this.fetch_wb_accounts()
	}
}
</script>
