<template>
    <div class="wb_keyword_component">
        <v-dialog v-model="dialog" max-width="500px" v-if="dialog">
            <v-card>
                <v-card-title class="text-h5 justify-center">Вы уверены, что хотите удалить?</v-card-title>
                <v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
					<v-btn color="blue darken-1" text @click="deleteKeyword()">OK</v-btn>
					<v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_keyword_seller" max-width="1800px" v-if="dialog_keyword_seller">
            <v-card>
                <v-card-title class="text-h5 justify-center">Кол-во запросов по товару</v-card-title>
                <v-card-text>
                    <v-data-table
                        :headers="header_keywords_seller" :items="data_keywords_seller"
                        class="elevation-1 vertical-border" dense hide-default-footer disable-pagination
                        :loading="loading_keyword_seller"
                    ></v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <BeeGridTable
            :key="itter"
            :loading="loading_table"
            border
            class="mt-5 keyword_monitoring_table"
            :showFilter="false"
            :showPager="false"
            height="460"
            :columns="keys"
            :data="list_data_prepare"
        >
            <template slot-scope="{ row }" slot="delete">
                <v-icon
                    small
                    @click="openDialog(row.id)"
                    v-if="row.isMain && !not_show_admin"
                >mdi-delete</v-icon>
            </template>
            <template slot-scope="{ row }" slot="favorite">
                <v-switch
                    v-if="row.isMain && !not_show_admin"
                    color="primary"
                    class="mt-0"
                    hide-details
                    :input-value="row.favorite"
                    v-model="row.favorite"
                    @change="changeFavoriteKeyword(row)"
                ></v-switch>
            </template>
            <template slot-scope="{ row }" slot="text_keyword">
                <a 
                    :href="urlWBMonitoring(row.keyword)"
                    target="_blank"
                    :class="{'error_keyword': row.is_data == false}"
                    v-if="row.isMain"
                >
                    {{row.keyword}}
                </a>
                <a v-else-if="row.is_card_analytics" class="dashed" @click="change_show_card">{{ row.keyword }}</a>
                <a v-else-if="row.is_keyword_seller" class="dashed" @click="openKeywordSeller">{{ row.keyword }}</a>
                <div v-else> {{row.keyword}} </div>
            </template>
            <template slot-scope="{ column, row  }" slot="posCell">
                <div v-if="row[column.key]['pos'] != 0 && row[column.key]['pos']">
					<v-tooltip bottom max-width="300">
						<template v-slot:activator="{ on, attrs }">
							<span 
								v-bind="attrs" v-on="on"
								:class="{'fill_cell_keyword': row.isMain, 'fill_index': row.isIndex}"
							>
								{{ row[column.key]["pos"] }}
							</span>
						</template>
						<span style="font-size: .7rem;">
							<div v-if="row[column.key]['auto'] && row[column.key]['auto'].length > 0">
								<p>Ставка автореклама: {{ row[column.key]['auto'][0] }}</p>
								<p>Позиция автореклама: {{ row[column.key]['auto'][1] }}</p>
							</div>
							<div v-else>Нет информации</div>
						</span>
					</v-tooltip>
                    <v-chip
                            v-if="row[column.key]['prevPos'] < 0"
                            class="mx-1 px-1 font-small"
                            color="red"
                            outlined
                            x-small
                    >
                        {{ row[column.key]["prevPos"] }}
                    </v-chip>
                    <v-chip
                            v-else-if="row[column.key]['prevPos'] > 0"
                            class="mx-1 px-1 font-small"
                            color="success"
                            outlined
                            x-small
                    >
                        {{ row[column.key]["prevPos"] }}
                    </v-chip>
                </div>
                <div v-else> 
					<v-tooltip bottom max-width="300">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">-</div>
						</template>
						<span style="font-size: .7rem;">
							<div v-if="row[column.key]['auto'] && row[column.key]['auto'].length > 0">
								<p>Ставка автореклама: {{ row[column.key]['auto'][0] }}</p>
								<p>Позиция автореклама: {{ row[column.key]['auto'][1] }}</p>
							</div>
							<div v-else>Нет информации</div>
						</span>
					</v-tooltip>
				</div>
            </template>
        </BeeGridTable>
    </div>
    
</template>

<script>
import { Products } from '@/api/products'
import { KeywordMonitoring } from '@/api/keyword_monitoring';
  export default {
    name: "KeywordTable",
    data(){
        return {
            itter: 1,
            dialog: false,
            current_delete: null,
            list_keys: null,
            list_data: null,
            loading_table: false,
            show_full_card_analytics: false,
            dialog_keyword_seller: false,
            loading_keyword_seller: false,
            data_keywords_seller: [],
            header_keywords_seller: []
        }
    },
    methods:{
        change_show_card(){
            this.show_full_card_analytics = !this.show_full_card_analytics
            this.itter += 1
        },
        openDialog(id){
            this.dialog = true
            this.current_delete = id
        },
        async deleteKeyword(){
            try {
                const response = await Products.deleteKeyword(this.current_delete)
                if (response.status == 204) {
                    await this.fetchData()
                }
                this.dialog = false
            }
            catch (err){
                console.log(err)
            }
        },
        urlWBMonitoring(text){
            this.scrollRight()
            this.calculateColor()
            this.calculateColorIndex()
            return `https://www.wildberries.ru/catalog/0/search.aspx?sort=popular&search=${text}`
        },
        async changeFavoriteKeyword(row){
            try {
                const response = await Products.updateFavoriteKeyword({
                    "id":row.id, "favorite": row.favorite
                })
                if (response.status == 204) {
                    await this.fetchData()
                }
            }
            catch (err){
                console.log(err)
            }
        },
        async fetchData(){
            this.loading_table = true
            try{
                const response = await Products.getKeywordMonitoring({"product_nmid": this.product_id, "favorite": this.favorite})
                this.list_keys = response.data.keys
                this.list_data = []
                if (response.data.index_day.length !=0)
                    this.list_data.push(...response.data.index_day)
                if (response.data.data.length !=0)
                    this.list_data.push(...response.data.data)
            } catch(err){
                console.log(err)
            }
            this.loading_table = false
        },
        calculateColor(){
            let data = document.getElementsByClassName('fill_cell_keyword')
            data.forEach(element => {
                let item = element.closest('td')
                if (item){
                    let value = element.textContent
                    let color = 'white'
                    if (value <= 10 && value > 0)
                        color = '#cef1c4'
                    else if (value <= 20 && value > 10)
                        color = '#def3d9'
                    else if (value <= 100 && value > 20)
                        color = '#eef5ed'
                    item.style.background = color
                }
                    
            })
        },
        calculateColorIndex(){
            let data = document.getElementsByClassName('fill_index')
            data.forEach(element => {
                let item = element.closest('td')
                if (item){
                    let value = element.textContent
                    let color = 'white'
                    if (value < 0)
                        color = '#fef5f6'
                    else if (value > 0)
                        color = '#f4faf4'
                    item.style.background = color
                }
                    
            })
        },
        scrollRight(){
            let block = document.querySelector(".keyword_monitoring_table .bee-table-body")
            if(block && block.scrollWidth != 0){
                setTimeout(() => {  block.scrollLeft =  block.scrollWidth }, 1);
            }
            let block_competitors = document.querySelector(".competitors .bee-table-body")
            if(block_competitors && block_competitors.scrollWidth != 0){
                setTimeout(() => {  block_competitors.scrollLeft =  block_competitors.scrollWidth }, 1);
            }
        },
        async fetchKeywordsSeller(){
            this.loading_keyword_seller = true
            try{
                const response = await KeywordMonitoring.getKeywordsSellerByNmid(this.product_id)
                this.data_keywords_seller = response.data.data
                this.header_keywords_seller = [{text: 'Фраза', align: 'start', sortable: true, value: 'keyword', width: '10%'},]
                response.data.date_list.forEach(item=>{
                    this.header_keywords_seller.push(
                        {text: item, align: 'start', sortable: true, value: item, width: '1%'}
                    )
                })
            } catch(err){
                console.log(err)
            }
            this.loading_keyword_seller = false
        },
        async openKeywordSeller(){
            this.dialog_keyword_seller = true
            await this.fetchKeywordsSeller()
        }
    },
    computed:{
        list_data_prepare(){
            if (this.show_full_card_analytics || !this.list_data)
                return this.list_data
            let result = []
            let is_alread_card = false
            this.list_data.forEach(item => {
                if (!item.is_card_analytics)
                    result.push(item)
                if (item.is_card_analytics && !is_alread_card){
                    result.push(item)
                    is_alread_card = true
                }
            })
            return result
        },
        keys(){
            let key = []
            if (this.list_keys){
                key = [
                    {fixed:"left", width: 45, align: "center", slot: "delete"},
                    {title: "Избранное", key: "favorite", fixed:"left", width: 70, resizable: true, align: "center", slot: "favorite"},
                    {title: "Ключевое слово", key: "keyword", fixed: "left", width: 350, resizable: true, align: "center", slot: "text_keyword" },
					{title: "Кластер", key: "cluster", fixed: "left", width: 75, resizable: true, align: "center"},
					{title: "Част. WB", key: "frequency_from_wb", fixed: "left", width: 75, resizable: true, align: "center"},
                    {title: "Частотность", key: "frequency", fixed: "left", width: 75, resizable: true, align: "center" },
                    {title: "Результатов", key: "results", fixed: "left", width: 80, resizable: true, align: "center" },
                    {title: "Ср. позиция", key: "avgPos", fixed: "left", width: 55, resizable: true, align: "center" },
                ]
                this.list_keys.forEach(element => {
                    let tmp_key = Object.keys(element)[0]
                    key.push(
                        {
                            title: element[tmp_key],
                            key: tmp_key,
                            width: 65, 
                            resizable: true,
                            align: "center",
                            slot: `posCell`
                        }
                    )
                })
            }
            else {
                for (let index = 0; index < 30; index++) {
                    key.push(
                        {
                            title: "",
                            key: "",
                            width: 90, 
                            resizable: true 
                        }
                    )
                }
            }
            return key

        }
    },
    props:["product_id", "favorite", "not_show_admin"],
    mounted(){
        this.fetchData()
    },
  }
</script>

<style lang="scss">
.error_keyword{
    color: red !important;
}
.bee-table-cell{
    padding: 0px 5px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wb_keyword_component .bee-table td {
	height: 35px !important;
}
</style>