<template>
    <div class="product_stock_balances">
		<div>
            <v-row class="">
                <v-col>
                    <v-menu v-model="datepicker_menu_from" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y="offset-y" min-width="290px"
                            ref="datepicker_menu_from">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                    clearable
                                    v-model="dataRangeText"
                                    label="Диапазон"
                                    prepend-icon="mdi-calendar"
                                    readonly="readonly"
                                    v-bind="attrs"
                                    hide-details="auto"
                                    outlined
                                    dense
                                    v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker
                                v-model="dates_range"
                                range
                                :first-day-of-week="1"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col>
                    <v-btn
                            @click="fetchProductDataDays()"
                            color="purple darken-2"
                            class="mb-3"
                            dark
                    >Показать</v-btn>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
        </div>
		<v-skeleton-loader
			v-if="balancesLoading || !summaryDataPerDay"
			type="table-heading, table-row@5"
			class="pa-4"
		></v-skeleton-loader>
        <BeeGridTable
			v-else
            border
            class="product_table mt-5"
            :showFilter="false"
            :showPager="false"
            height="800"
            :columns="keys"
            :data="data_test"
            
        >
			<template slot-scope="{ column }" slot="column_name">
				<div 
					:class="['d-flex', {'yellow-cell': column.comment}]" 
					style="flex-direction: column; align-items: center; padding: 0px 5px;"
				>
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div
									v-bind="attrs"
									v-on="on"
							>
								<a 
									@click="openDialogComment(column.key)" 
									class="dashed small-text"
								>{{ column.title | formatDateToLocaleNoTime }}</a>
								<v-icon  
									small 
									color="blue lighten-2" 
									v-if="artificial_sales_dates.includes(column.title)"
								>mdi-account-arrow-down</v-icon>
							</div>
						</template>
						<span>
							<div class="small-text">
								Комментарий: {{column.comment}}
							</div>
						</span>
					</v-tooltip>
				</div>
			</template>
			<template slot-scope="{ column, row}" slot="head">
                <div :class="['small-text', {'black-cell': emptyCell(row.label)}]">
					{{row[column.key]}}
					<v-tooltip bottom max-width=350 v-if="calcHelpText(row.label) != ''">
						<template v-slot:activator="{ on, attrs }">
							<span v-bind="attrs" v-on="on" style="cursor: pointer">
								<v-icon x-small class="ml-2">mdi-help-circle-outline</v-icon>
							</span>
						</template>
						<span>{{ calcHelpText(row.label) }}</span>
					</v-tooltip>
				</div>
            </template>
			<template slot-scope="{ column, row}" slot="data">
				<div class="black-cell" v-if="emptyCell(row.label)">
					{{row[column.key]}}
				</div>
				<div v-else-if="row.label=='price'">
					<div class="d-flex align-center">
						<v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][8]">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on" style="width: auto;">
									<a href="#" class="dashed small-text">{{ row[column.key][8] | toRUB}}</a>
								</div>
							</template>
							<span>
								<div class="small-text">Цена из заказов: {{row[column.key][9] ? row[column.key][9] : 0 | toRUB}}</div>
								<div class="small-text">Цена с учетом всех акций: {{row[column.key][2] ? row[column.key][2] : 0 | toRUB}}</div>
								<div class="small-text">Цена со скидкой: {{row[column.key][0] ? row[column.key][0] : 0 | toRUB}}</div>
								<div class="small-text">Цена Premium: {{row[column.key][1] ? row[column.key][1] : 0 | toRUB}}</div>
								<div><br><hr><br></div>
								<div class="small-text">price: {{row[column.key][0] ? row[column.key][0] : 0 | toRUB}}</div>
								<div class="small-text">premium_price: {{row[column.key][1] ? row[column.key][1] : 0 | toRUB}}</div>
								<div class="small-text">marketing_price: {{row[column.key][2] ? row[column.key][2] : 0 | toRUB}}</div>
								<div class="small-text">old_price: {{row[column.key][3] ? row[column.key][3] : 0 | toRUB}}</div>
								<div class="small-text">min_ozon_price: {{row[column.key][4] ? row[column.key][4] : 0 | toRUB}}</div>
								<div class="small-text">min_price: {{row[column.key][5] ? row[column.key][5] : 0 | toRUB}}</div>
								<div class="small-text">recommended_price: {{row[column.key][6] ? row[column.key][6] : 0 | toRUB}}</div>
								<div class="small-text">retail_price: {{row[column.key][7] ? row[column.key][7] : 0 | toRUB}}</div>
								<div class="small-text">marketing_seller_price: {{row[column.key][8] ? row[column.key][8] : 0 | toRUB}}</div>
							</span>
						</v-tooltip>
						<div v-else class="small-text" style="width: auto;">-</div>
						<v-icon 
							x-small class="ml-2" v-if="column._index == 1" @click="changePriceDialog = true"
						>mdi-pencil</v-icon>
					</div>
				</div>
				<div v-else-if="row.label=='new_logistic'">
					<v-tooltip bottom max-width=350 v-if="row[column.key]">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="width: auto;">
								<a href="#" class="dashed small-text">{{ row[column.key][4] | toRUB}}</a>
							</div>
						</template>
						<span>
							<div class="small-text no_wrap">Базовая логистика: {{row[column.key][0] | toRUB}}</div>
							<div class="small-text no_wrap">Индекс локализации: {{row[column.key][1]}} %</div>
							<div class="small-text no_wrap">Максимальная логистика: {{row[column.key][2] | toRUB}}</div>
							<div class="small-text no_wrap">Минимальная логистика: {{row[column.key][3] | toRUB}}</div>
							<div class="small-text no_wrap">Текущая логистика: {{row[column.key][4] | toRUB}}</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='price_promotions'">
					<div class="d-flex align-center">
						<v-tooltip bottom max-width=450 color="#2c2c2c" v-if="row[column.key] && row[column.key][0] && row[column.key].length > 0">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on" style="width: auto;">
									<a href="#" class="dashed small-text">{{row[column.key][1]}}</a>
								</div>
							</template>
							<span>
								<p>Комиссия в акции = {{ row[column.key][1] }}%</p>
								<p>Базовая комиссия = {{ row[column.key][0] }}%</p>
								<div v-for="(item, index) in row[column.key][2]" :key="index">
									<p>{{ item.title }} ({{ item.commission_reduction }})</p>
								</div>	
																
							</span>
						</v-tooltip>
						<div v-else class="small-text" style="width: auto;">-</div>
					</div>
				</div>
				<div v-else-if="row.label=='localization_index_product'">
					<div class="small-text" :class="{
						'green-index': row[column.key] >= 75,
						'yellow-index': row[column.key] < 75 && row[column.key] >= 65,
						'red-index': row[column.key] < 65,
					}">{{ row[column.key] ? row[column.key] : 0 }}</div>
				</div>
				<div v-else-if="row.label=='price_indexes'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][0]">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" :class="{
								'green-index': row[column.key][0] == 'PROFIT',
								'yellow-index': row[column.key][0] == 'AVG_PROFIT',
								'red-index': row[column.key][0] == 'NON_PROFIT',
								'without-index': row[column.key][0] == 'WITHOUT_INDEX',
							}">
								<a href="#" class="dashed small-text">{{ row[column.key][0] }}</a>
							</div>
						</template>
						<span>
							<div class="small-text">external_index_minimum_price: {{row[column.key][1] ? row[column.key][1] : 0 | toRUB}}</div>
							<div class="small-text">external_index_value: {{row[column.key][2] ? row[column.key][2] : 0}}</div>
							<div class="small-text">ozon_index_minimum_price: {{row[column.key][3] ? row[column.key][3] : 0 | toRUB}}</div>
							<div class="small-text">ozon_index_value: {{row[column.key][4] ? row[column.key][4] : 0}}</div>
							<div class="small-text">other_index_minimum_price: {{row[column.key][5] ? row[column.key][5] : 0 | toRUB}}</div>
							<div class="small-text">other_index_value: {{row[column.key][6] ? row[column.key][6] : 0}}</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div class="small-text" v-else-if="row.label == 'cost_price'">
					<a class="dashed" @click="openCostPriceDetailsDialog(column.key)" v-if="row[column.key]">
						{{ row[column.key] | toRUB}}
					</a>
					<span class="small-text" v-else>-</span>
				</div>
				<div class="yellow-cell" v-else-if="row.label=='vp_plan'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][0]">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][7] > 0}]">
                                    {{row[column.key][0] ? row[column.key][0].toFixed(2) : 0}}
                                </a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Комиссия(руб): {{
									row[column.key][1] && row[column.key][3] ? 
									row[column.key][1] * row[column.key][3] / 100 : 
									0 | toRUB
								}}
							</div>
							<div class="small-text">
								Логистика(руб): {{row[column.key][6] ? row[column.key][6] : row[column.key][2] | toRUB}}
							</div>
							<div class="small-text">
								Налог: 
								{{row[column.key] && row[column.key][4] ? row[column.key][4] : 0 | toRUB}}
								({{row[column.key] && row[column.key][5] ? row[column.key][5] : 0 }} %)
							</div>
							<div class="small-text">
								Эквайринг(руб): {{row[column.key][3] ? row[column.key][3] * 0.015 : 0 | toRUB}}
							</div>
							<div class="small-text">
								Потери при возвратах: {{row[column.key][8] * (1 - row[column.key][9]) | toRUB}}({{ row[column.key][9] * 100 }}%)
							</div>
							<div class="small-text">
								Последняя миля из заказов: {{row[column.key][10] * 0.055 | toRUB}}
							</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div class="yellow-cell" style="display: block;" v-else-if="row.label=='rentab' || row.label=='markup'">
                    <div 
                        :class="['small-text', {'bold_text': row[column.key][1] > 0}]"
                    >{{ row[column.key][0] ? (row[column.key][0] * 100).toFixed(2) : 0 }}%</div>
                </div>
				<div v-else-if="
					row.label=='uvz' || 
					row.label=='uvo' || 
					row.label=='orders_api_amount' ||
					row.label=='redemption_sum' ||
					row.label=='distribution_sum' ||
					row.label=='distribution_cashback' ||
					row.label=='sales_sum' ||
					row.label=='warenwert_mp' ||
					row.label=='bloggers_expenses'
				">
					<div 
						class="small-text" 
						v-if="row[column.key]" 
					>{{ row[column.key] | toRUB }}</div>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='speed_orders'">
					<div 
						class="small-text" 
						v-if="row[column.key]" 
					>{{ row[column.key].toFixed(2) }}</div>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='speed_orders_7'">
                    <div class="small-text" v-if="row[column.key]" v-text="row[column.key].toFixed(2)"></div>
                </div>
				<div class="yellow-cell" v-else-if="row.label=='vp_orders_plan' || row.label=='vp_mp_orders_plan'">
					<div 
                        :class="['small-text', {'bold_text': row[column.key][1] > 0}]"
                    >{{ row[column.key][0] ? row[column.key][0] : 0 | toRUB }}</div>
				</div>
				<div v-else-if="row.label=='redemption_expenses'">
					<v-tooltip 
						bottom max-width=350 
						v-if="row[column.key] && row[column.key][1] && (row[column.key][0] || row[column.key][2] || row[column.key][3] || row[column.key][4])"
					>
						<template v-slot:activator="{ on, attrs }">
							<div
									v-bind="attrs"
									v-on="on"
							>
								<a href="#" class="dashed small-text"  v-if="row[column.key][0]">
									{{row[column.key][0] ? row[column.key][0] : 0 | toRUB }}
								</a>
								<a href="#" class="dashed small-text" v-else-if="row[column.key][4] > 0">
									{{
										row[column.key][2] || row[column.key][4] ? 
										row[column.key][1] * row[column.key][2] / 100 + row[column.key][4] : 0 | toRUB 
									}}
								</a>
								<a href="#" class="dashed small-text" v-else>
									{{
										row[column.key][2] || row[column.key][3] ? 
										row[column.key][1] * row[column.key][2] / 100 + row[column.key][3] : 0 | toRUB 
									}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text" v-if="row[column.key][4] > 0">
								Расход план: {{
										row[column.key][2] || row[column.key][4] ? 
										row[column.key][1] * row[column.key][2] / 100 + row[column.key][4] : 0 | toRUB 
									}}
							</div>
							<div class="small-text" v-else>
								Расход план: {{
										row[column.key][2] || row[column.key][3] ? 
										row[column.key][1] * row[column.key][2] / 100 + row[column.key][3] : 0 | toRUB 
									}}
							</div>
							<div class="small-text">
								Расход факт: {{row[column.key][0] ? row[column.key][0] : 0 | toRUB }}
							</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='sale_cost_price'">
					<div v-if="row[column.key] && row[column.key][0] && row[column.key][1]" class="small-text">
						{{row[column.key][0] * row[column.key][1] | toRUB}}
					</div>
					<div v-else class="small-text">-</div>
				</div>
				<div class='yellow-cell' v-else-if="row.label=='vp_sale'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
						<template v-slot:activator="{ on, attrs }">
							<div
									v-bind="attrs"
									v-on="on"
							>
								<a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
									{{row[column.key][2] ? row[column.key][0] : row[column.key][1] | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Факт: {{row[column.key] ? row[column.key][0]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Расчет.: {{row[column.key] ? row[column.key][1]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Логистика: {{row[column.key] ? row[column.key][3]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Комиссия.: {{row[column.key] ? row[column.key][4] * -1: 0 | toRUB}}
							</div>
							<div class="small-text">
								Other: {{row[column.key] ? row[column.key][5]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Налог: 
								{{row[column.key] ? row[column.key][6]: 0 | toRUB}} 
								({{row[column.key] ? row[column.key][7]: 0}}%)
							</div>
						</span>
					</v-tooltip>
				</div>
				<div class='yellow-cell' v-else-if="row.label=='vp_sale_single'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && (row[column.key][0] || row[column.key][1])">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
									{{row[column.key][2] ? row[column.key][0] : row[column.key][1] | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								C отчета: {{row[column.key] ? row[column.key][0]: 0 | toRUB}}
							</div>
							<div class="small-text">
								Расчет.: {{row[column.key] ? row[column.key][1]: 0 | toRUB}}
							</div>
							<div class="small-text" v-if='row[column.key] && row[column.key][2] && row[column.key][3] && row[column.key][0]'>
								Рентабельность: {{(row[column.key][0] / (row[column.key][2] / row[column.key][3]) * 100).toFixed(2)}}%
							</div>
							<div class="small-text" v-if='row[column.key] && row[column.key][2] && row[column.key][0] && row[column.key][4]'>
								Наценка: {{(row[column.key][0] / row[column.key][4] * 100).toFixed(2)}}%
							</div>
						</span>
					</v-tooltip>
				</div>
				<div v-else-if="row.label=='drr' || row.label=='drr_full'">
					<span class="small-text">
						{{ row[column.key] ? row[column.key].toFixed(2) : 0 }}%
					</span>
                </div>
				<div v-else-if="row.label=='warenwert_1c'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && row[column.key][0] && (row[column.key][1] || row[column.key][2])">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text">
									{{ row[column.key][0] * (row[column.key][1] + row[column.key][2]) | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Склад: {{row[column.key][1] * row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Основной компонент: {{row[column.key][2] * row[column.key][0] | toRUB}}
							</div>
						</span>
					</v-tooltip>
					<div class="small-text" v-else>-</div>
				</div>
				<div v-else-if="row.label == 'storage_cost'">
					<div class="small-text">{{ row[column.key] ? row[column.key] : 0 | toRUB }}</div>
				</div>
				<div v-else-if="row.label=='warehouse_expenses'">
					<v-tooltip bottom max-width=350 v-if="row[column.key]">
						<template v-slot:activator="{ on, attrs }">
							<div
									v-bind="attrs"
									v-on="on"
							>
								<a 
									class="small-text dashed" 
									@click="openShippingPriceDialog(column.key)"
								>
									{{sumShippingPacks(row[column.key]) | toRUB}}
								</a>
							</div>
						</template>
						<span>
							<div 
								class="small-text" 
								v-for="(item, index) in row[column.key]"
								:key="index"
							>
								{{item.type}}: {{item.sum_cost_one ? item.sum_cost_one: 0 | toRUB}}
							</div>
						</span>
					</v-tooltip>
					<span class="small-text" v-else>-</span>
				</div>
				<div v-else-if="row.label=='stock_api'">
					<span style="display: block;">
						<v-tooltip bottom max-width=350 v-if="row[column.key]">
							<template v-slot:activator="{ on, attrs }">
								<div v-bind="attrs" v-on="on">
									<span class="dashed small-text" @click="openStocksWarehouseDialog(column.key)" style="cursor: pointer;">
										<div><span class="grey--text">Склад: </span>{{row[column.key][0] ? row[column.key][0] : 0 }}</div>
									</span>
								</div>
							</template>
							<span>
								<div class="small-text">
									fbs: {{row[column.key][1] ? row[column.key][1] : 0}}
								</div>
								<div class="small-text">
									fbo: {{row[column.key][2] ? row[column.key][2] : 0}}
								</div>
							</span>
						</v-tooltip>
						<v-tooltip bottom max-width=350 v-if="row[column.key]" class="mt-3">
							<template v-slot:activator="{ on, attrs }">
								<div
										v-bind="attrs"
										v-on="on"
								>
									<a href="#" class="dashed small-text">
										<div><span class="grey--text">Зарезер.: </span>{{ row[column.key][3] ? row[column.key][3] : 0 }}</div>
									</a>
								</div>
							</template>
							<span>
								<div class="small-text">
									fbs: {{row[column.key][4] ? row[column.key][4] : 0}}
								</div>
								<div class="small-text">
									fbo: {{row[column.key][5] ? row[column.key][5] : 0}}
								</div>
							</span>
						</v-tooltip>
						<div v-if="row[column.key]" class="small-text">
							<span class="grey--text">Ожид. постав.: </span>
							{{ row[column.key][6] ? row[column.key][6] : 0}}
						</div>
					</span>
				</div>
				<div v-else-if="row.label=='comission_fbo'">
					<a @click="openDiagComissionFbo(row[column.key])" class="small-text" v-if="row[column.key] && (
						row[column.key][0] | 
						row[column.key][1] | 
						row[column.key][2] | 
						row[column.key][3]
					)">{{ row[column.key][0] * row[column.key][1] / 100 + row[column.key][2] + row[column.key][3] | toRUB}}</a>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='comission_fbs'">
					<a @click="openDiagComissionFbs(row[column.key])" class="small-text" v-if="row[column.key] && (
						row[column.key][0] | 
						row[column.key][1] | 
						row[column.key][2] | 
						row[column.key][3] |
						row[column.key][4]
					)">
						{{ row[column.key][0] * row[column.key][1] / 100 + row[column.key][2] + row[column.key][4] | toRUB}} - 
						{{ row[column.key][0] * row[column.key][1] / 100 + row[column.key][2] + row[column.key][4] + 25 | toRUB}}
					</a>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='stock_1c'">
					<v-tooltip bottom max-width=350 v-if="row[column.key] && (row[column.key][0] || row[column.key][1] || row[column.key][2])">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text" :class="{'bold-text': row[column.key][2]}">
									{{ row[column.key][0] || row[column.key][2] ? Math.floor(row[column.key][0] + row[column.key][2]) : '-'}}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Остаток на складе: {{row[column.key] ? row[column.key][0]: 0}}
							</div>
							<div class="small-text">
								Остаток основного компонента: {{row[column.key] ? row[column.key][2]: 0}}
							</div>
							<div class="small-text">
								Возможные комплекты: {{row[column.key] ? row[column.key][1]: 0}}
							</div>
						</span>
					</v-tooltip>
				</div>
				<div v-else-if="row.label=='expenses_search' || row.label=='expenses_other' || row.label=='expenses_brand'">
					<div class="small-text" v-if="row[column.key]">{{row[column.key] | toRUB}}</div>
					<div class="small-text" v-else>-</div>
				</div>
				<div v-else-if="row.label=='click_cost'">
					<div class="small-text" v-if="row[column.key] && row[column.key][0] && row[column.key][1]">
						{{row[column.key][0] / row[column.key][1] | toRUB}}
					</div>
					<div class="small-text" v-else>-</div>
				</div>
				<div v-else-if="row.label=='transaction_expenses'">
					<v-tooltip bottom max-width=550 v-if="row[column.key] && row[column.key][0]">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a class="small-text dashed" >
									{{sumTransactionExpenses(row[column.key][0]) | toRUB}} 
									({{ calculatePercent(sumTransactionExpenses(row[column.key][0]) * -1, row[column.key][1]).toFixed(2) }}%)
								</a>
							</div>
						</template>
						<span>
							<div 
								class="small-text" 
								v-for="(item, index) in row[column.key][0]"
								:key="index"
							>
								<p v-if="item.sum_expenses">
									{{getTransactionExpensesName(item.name)}}: 
									{{item.sum_expenses ? item.sum_expenses: 0 | toRUB}} 
									({{calculatePercent(item.sum_expenses * -1, row[column.key][1]).toFixed(2)}}%)
								</p>
							</div>
						</span>
					</v-tooltip>
					<span class="small-text" v-else>-</span>
				</div>
				<div v-else-if="row.label=='volume_weight'">
					<a class="dashed small-text" @click="openDialogChars(column.key)">
						{{ 
							row[column.key][0] && row[column.key][1] && row[column.key][2] ? 
							row[column.key][0] * row[column.key][1] * row[column.key][2] / 1000000 : 
							0 
						}}
					</a>
				</div>
				<div v-else-if="row.label=='avg_vp_30_days'">
					<v-tooltip 
						bottom max-width=350 
						v-if="row[column.key] && row[column.key][5]"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text">
									{{row[column.key][5] | toRUB }}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Сумма продаж: {{row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Количество продаж: {{row[column.key][1].toFixed(0)}}
							</div>
							<div class="small-text">
								Средняя себестоимость: {{row[column.key][2] | toRUB}}
							</div>
							<div class="small-text">
								Средняя цена: {{row[column.key][3] | toRUB}}
							</div>
							<div class="small-text">
								Сумма ВП: {{row[column.key][4] | toRUB}}
							</div>
							<div class="small-text">
								Среднее ВП: {{row[column.key][5] | toRUB}}
							</div>
							<div class="small-text">
								Средняя рентабельность: {{row[column.key][6] ? (row[column.key][6] * 100).toFixed(2) : 0}}%
							</div>
							<div class="small-text">
								Средняя наценка: {{row[column.key][7] ? (row[column.key][7] * 100).toFixed(2) : 0}}%
							</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='avg_vp_7_days'">
					<v-tooltip 
						bottom max-width=350 
						v-if="row[column.key] && row[column.key][5]"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a href="#" class="dashed small-text">
									{{row[column.key][5] | toRUB }}
								</a>
							</div>
						</template>
						<span>
							<div class="small-text">
								Сумма продаж: {{row[column.key][0] | toRUB}}
							</div>
							<div class="small-text">
								Количество продаж: {{row[column.key][1].toFixed(0)}}
							</div>
							<div class="small-text">
								Средняя себестоимость: {{row[column.key][2] | toRUB}}
							</div>
							<div class="small-text">
								Средняя цена: {{row[column.key][3] | toRUB}}
							</div>
							<div class="small-text">
								Сумма ВП: {{row[column.key][4] | toRUB}}
							</div>
							<div class="small-text">
								Среднее ВП: {{row[column.key][5] | toRUB}}
							</div>
							<div class="small-text">
								Средняя рентабельность: {{row[column.key][6] ? (row[column.key][6] * 100).toFixed(2) : 0}}%
							</div>
							<div class="small-text">
								Средняя наценка: {{row[column.key][7] ? (row[column.key][7] * 100).toFixed(2) : 0}}%
							</div>
						</span>
					</v-tooltip>
					<div v-else class="small-text">-</div>
				</div>
				<div v-else-if="row.label=='vp_cp'">
					<span v-if="row[column.key] && row[column.key][2]" class="small-text bold-text">
						{{row[column.key][0] - row[column.key][3] - row[column.key][4] - row[column.key][5] - row[column.key][6]| toRUB}}
					</span>
					<span v-else class="small-text">
						{{row[column.key][1] - row[column.key][3] - row[column.key][4] - row[column.key][5] - row[column.key][6] | toRUB}}
					</span>
                </div>
				<div v-else-if="row.label=='rentab_cp_plan'">
					<div class="small-text yellow-cell">
						{{ row[column.key] ? row[column.key].toFixed(2) : 0 }}%
					</div>
				</div>
				<div v-else-if="row.label=='profit_plan'">
					<div class="small-text yellow-cell">
						{{ row[column.key] ? row[column.key] : 0 |toRUB }}
					</div>
				</div>
				<div v-else-if="row.label=='rentab_vp_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if=row[column.key]>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][3] > 0}]">
                                        {{ row[column.key][0] ? row[column.key][0].toFixed(2) : 0 }}%
                                    </a>
                                </div>
                            </template>
                            <span>
                                <div class="small-text">
                                    ВП_ЛС прогноз: {{ row[column.key][1] ? row[column.key][1].toFixed(2) : 0 }}%
                                </div>
                                <div class="small-text">
                                    ВП_М прогноз: {{ row[column.key][2] ? row[column.key][2].toFixed(2) : 0 }}%
                                </div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
				<div v-else-if="row.label=='vp_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if=row[column.key]>
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <a href="#" :class="['dashed', 'small-text', {'bold-text': row[column.key][3] > 0}]">
                                        {{ row[column.key][0] ? row[column.key][0] : 0 | toRUB}}
                                    </a>
                                </div>
                            </template>
                            <span>
                                <div class="small-text">
                                    ВП_ЛС прогноз: {{ row[column.key][1] ? row[column.key][1] : 0 | toRUB}}
                                </div>
                                <div class="small-text">
                                    ВП_М прогноз: {{ row[column.key][2] ? row[column.key][2] : 0 | toRUB}}
                                </div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
				<div v-else-if="row.label=='warehousing_mp'">
					<div class="small-text yellow-cell">
                        <v-tooltip bottom max-width=350 v-if="row[column.key]">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    {{ row[column.key][0]| toRUB}}
                                </div>
                            </template>
                            <span>
                                <div class="small-text">Хранение МП: {{row[column.key][1] | toRUB}}</div>
                                <div class="small-text">Штрафы: {{row[column.key][2] | toRUB}}</div>
                                <div class="small-text">Транзит: {{row[column.key][3] | toRUB}}</div>
                                <div class="small-text">Другие расходы МП: {{row[column.key][4] | toRUB}}</div>
                            </span>
                        </v-tooltip>
					</div>
				</div>
				<div class='green-cell' v-else-if="row.label=='warehousing_expenses'">
					<v-tooltip bottom max-width=350>
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on">
								<a
									v-if="row[column.key] && (row[column.key][0] > 0 || row[column.key][1] > 0 || row[column.key][2] > 0 || row[column.key][3] > 0 || row[column.key][4] > 0 || row[column.key][5] > 0)"
								>
									{{ (row[column.key][0] + row[column.key][1] + row[column.key][2] + row[column.key][3] + row[column.key][4] + row[column.key][5]) | toRUB}}
								</a>
								<a v-else>0</a>
							</div>
						</template>
						<span>
							<div class="small-text">Доставка до МП: {{row[column.key][0] | toRUB}}</div>
							<div class="small-text">Приемка: {{row[column.key][1] | toRUB}}</div>
							<div class="small-text">Хранение: {{row[column.key][2] | toRUB}}</div>
							<div class="small-text">Сборка: {{row[column.key][3] | toRUB}}</div>
							<div class="small-text">Фасовка: {{row[column.key][4] | toRUB}}</div>
							<div class="small-text">Производство МИ: {{row[column.key][5] | toRUB}}</div>
							<div class="small-text">Кол-во циклов за год: {{row[column.key][6]}}</div>
						</span>
					</v-tooltip>
                </div>
				<div v-else-if="row.label=='card_analytics'">
                    <div class="small-text" style="display: flex; flex-direction: column" :class="{'bold_card_analytics': row.naming=='Переходы в карточку, шт'}">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0]: 0 }}</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1] ? row[column.key][1] : 0 }})
                        </p>
                    </div>
                </div>
                <div v-else-if="row.label=='card_analytics_per'">
                    <div class="small-text" style="display: flex; flex-direction: column" :class="{'bold_card_analytics': row.naming=='CR переход - заказ'}">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0].toFixed(1): 0 }} %</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1]? row[column.key][1].toFixed(1): 0 }} %)
                        </p>
                    </div>
                </div>
                <div v-else-if="row.label=='card_analytics_rub'">
                    <div class="small-text" style="display: flex; flex-direction: column">
                        <p style="line-height: 1.2;" class="mb-0">{{ row[column.key][0] ? row[column.key][0]: 0 | toRUB }}</p>
                        <p style="line-height: 1.2;" class="mb-0" :class="{'green_delta': row[column.key][1] > 0, 'red_delta': row[column.key][1] < 0}">
                            ({{ row[column.key][1] ? row[column.key][1]: 0 | toRUB }})
                        </p>
                    </div>
                </div>
                <div v-else class="small-text">
					<div class="small-text" v-if="row[column.key]">{{row[column.key]}}</div>
					<div class="small-text" v-else>-</div>
				</div>
            </template>
        </BeeGridTable>
		<v-dialog
			v-model="costPriceDetailsDialog"
			width="600"
			v-if="costPriceDetailsDialog"
		>
			<v-card>
				<v-card-title class="text-h5">
					Себестоимость на {{ activeCostPriceDetailsDay | formatDateToLocaleNoTime}}
				</v-card-title>
				<div style="height: 400px;">
					<CostPriceDetails 
						:catalog_item_id="catalog_item_id"
						:date="activeCostPriceDetailsDay"
					></CostPriceDetails>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="stocksWarehouseDialog" v-if="stocksWarehouseDialog">
			<v-card>
				<v-card-title class="text-h5">
					Остатки FBO на {{ activeStocksWarehouseDay | formatDateToLocaleNoTime}}
				</v-card-title>
				<div style="height: 400px;">
					<OzonStocksFboByWarehouse 
						:product_id="product_id"
						:date="activeStocksWarehouseDay"
					></OzonStocksFboByWarehouse>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="shippingPriceDialog"
			width="1000"
			v-if="shippingPriceDialog"
		>
			<v-card>
				<v-card-title class="text-h5">
					Расходы по складу на {{ activeShippingPriceDay | formatDateToLocaleNoTime}}
				</v-card-title>
				<div style="height: 700px;">
					<ShippingPriceByDay 
						:catalog_sku="catalog_item_id"
						:date="activeShippingPriceDay"
					></ShippingPriceByDay>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialog_detail_comission_fbo"
			max-width="600"
			v-if="dialog_detail_comission_fbo"
			scrollable
		>
			<v-card>
				<v-card-text class="mt-5">
					<h1>{{ detail_comission_fbo[0] * detail_comission_fbo[1] / 100 + detail_comission_fbo[2] + detail_comission_fbo[3] | toRUB}}</h1>
					<p class="mt-2">Склад ОЗОН</p>
					<h4>Комиссия за продажу: ({{detail_comission_fbo[1]}}%) {{
						detail_comission_fbo[0] && detail_comission_fbo[1] ? 
						detail_comission_fbo[0] * detail_comission_fbo[1] / 100 : 0 | toRUB
					}}</h4>
					<h4>Логистика: {{
						detail_comission_fbo[2] ? detail_comission_fbo[2] : 0 | toRUB
					}}</h4>
					<h4>Последняя миля: {{
						detail_comission_fbo[3] ? detail_comission_fbo[3] : 0 | toRUB
					}}</h4>
					<h2 class="mt-5">Возврат или отмена: {{
						detail_comission_fbo[4] || detail_comission_fbo[3] ? 
						detail_comission_fbo[4] + detail_comission_fbo[5] : 0 | toRUB
					}}</h2>
					<h4 class="mt-4">Обратная логистика: {{
						detail_comission_fbo[4] ? detail_comission_fbo[4] : 0 | toRUB
					}}</h4>
					<h4>Обработка возврата: {{
						detail_comission_fbo[5] ? detail_comission_fbo[5] : 0 | toRUB
					}}</h4>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="dialog_detail_comission_fbs"
			max-width="600"
			v-if="dialog_detail_comission_fbs"
			scrollable
		>
			<v-card>
				<v-card-text class="mt-5">
					<h1>
						{{ detail_comission_fbs[0] * detail_comission_fbs[1] / 100 + detail_comission_fbs[2] + detail_comission_fbs[4] | toRUB}} - 
						{{ detail_comission_fbs[0] * detail_comission_fbs[1] / 100 + detail_comission_fbs[2] + detail_comission_fbs[4] + 25 | toRUB}}
					</h1>
					<p class="mt-2">Мой склад</p>
					<h4>Комиссия за продажу: ({{detail_comission_fbs[1]}}%) {{
						detail_comission_fbs[0] && detail_comission_fbs[1] ? 
						detail_comission_fbs[0] * detail_comission_fbs[1] / 100 : 0 | toRUB
					}}</h4>
					<h4>Обработка отправления: 
						{{ 0 | toRUB}} - {{ 25 | toRUB }}
					</h4>
					<h4>Логистика: 
						{{detail_comission_fbs[2] ? detail_comission_fbs[2] : 0 | toRUB}}
					</h4>
					<h4>Последняя миля: {{
						detail_comission_fbs[4] ? detail_comission_fbs[4] : 0 | toRUB
					}}</h4>
					<h2 class="mt-5">
						Возврат или отмена: 
						{{detail_comission_fbs[5] || detail_comission_fbs[7] ? detail_comission_fbs[5] + detail_comission_fbs[7] : 0 | toRUB}} - 
						{{detail_comission_fbs[6] || detail_comission_fbs[7] ? detail_comission_fbs[6] + detail_comission_fbs[7] : 0 | toRUB}}
					</h2>
					<h4 class="mt-4">
						Обратная логистика: 
						{{detail_comission_fbs[5] ? detail_comission_fbs[5] : 0 | toRUB}} - 
						{{detail_comission_fbs[6] ? detail_comission_fbs[6] : 0 | toRUB}}
					</h4>
					<h4>Обработка возврата: {{
						detail_comission_fbs[7] ? detail_comission_fbs[7] : 0 | toRUB
					}}</h4>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="commentDialog"
			max-width="600"
			v-if="commentDialog"
			scrollable
		>
			<v-card>
				<v-card-title class="text-h5">
					Комментарий на {{activeChangeCommentDay | formatDateToLocaleNoTime}}
				</v-card-title>
				<div class="px-5 py-5">
					<v-textarea
						v-model="form_comment.text"
						label="Комментарий"
						outlined
						clearable
						auto-grow
						rows="3"
						col="2"
						hide-details
						dense
					></v-textarea>
					<div class="d-flex justify-space-between mt-5">
						<v-btn class="mt-5" @click="saveComment()">Сохрарить</v-btn>
						<v-btn class="mt-5" color="error" @click="saveComment(true)">Удалить</v-btn>
					</div>
					
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="chars_dialog" v-if="chars_dialog" scrollable>
			<v-card>
				<v-card-title class="text-h5">Характеристики на {{ chars_data.date }}</v-card-title>
				<v-card-text v-if="Object.keys(chars_data).length > 0">
					<h2 class="pb-5 mt-5">Основные характеристики</h2>
					<p>Высота - {{ chars_data.height ? chars_data.height: 0 }}</p>
					<p>Ширина - {{ chars_data.width ? chars_data.width: 0 }}</p>
					<p>Глубина - {{ chars_data.depth ? chars_data.depth: 0 }}</p>
					<p>Вес - {{ chars_data.weight ? chars_data.weight: 0 }}</p>
					<h2 class="pb-5 mt-5">Аттрибуты</h2>
					<div v-for="(item, index) in chars_data.attributes" :key="index">
						<p v-if="item.value.length > 0 && item.value[0]" :title="item.desc" style="cursor:pointer">
							{{ item.name }} - {{convert_chars_value(item.value)}}
						</p>
					</div>
					<h2 class="pb-5 mt-5">Аттрибуты без значения</h2>
					<div v-for="(item, index) in chars_data.attributes_not_filled" :key="index">
						<p :title="item.desc" style="cursor:pointer">
							{{ item.name }}
						</p>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog
                v-model="changePriceDialog"
                max-width="600"
                v-if="changePriceDialog"
                scrollable
        >
            <v-card>
                <v-card-title class="text-h5">Изменение цены</v-card-title>
                <OzonChangePrice
                        :product_id="product_id"
                        :current_min_price="summaryDataPerDay[0].min_price"
                        :current_price="summaryDataPerDay[0].price"
                        :current_old_price="summaryDataPerDay[0].old_price"
                        :comission="summaryDataPerDay[0].sales_percent_fbo"
                        :logistic="summaryDataPerDay[0].current_logistic ? summaryDataPerDay[0].current_logistic: summaryDataPerDay[0].fbo_direct_flow_trans_min_amount"
                        :last_mile="summaryDataPerDay[0].fbo_deliv_to_customer_amount"
						:cost_price="summaryDataPerDay[0].cost_price"
						:tax="summaryDataPerDay[0].tax_percent"
                        @finishUpdatePrice="finishUpdatePrice"
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
	import {Products} from '@/api/products';
	import CostPriceDetails from '@/components/Product/CostPriceDetails';
	import ShippingPriceByDay from '@/components/Product/ShippingPriceByDay'
	import OzonStocksFboByWarehouse from '@/components/Products/OzonStocksFboByWarehouse'
	import OzonChangePrice from '@/components/Products/OzonChangePrice'

	export default {
		name: 'OzonProductDataDaysNew',
		components: {CostPriceDetails, ShippingPriceByDay, OzonStocksFboByWarehouse, OzonChangePrice},
		props: {
			product_id: {
				type: String,
			},
			catalog_item_id: {
				type: String,
			},
			artificial_sales_dates: {
				type: Array,
				required: false
			}
		},
		data() {
			return {
				changePriceDialog: false,
				datepicker_menu_from: false,
				datepicker_menu_to: false,
				date_from: null,
				date_to: null,
				dates_range: [],
				SERVICE_MAP_LOCALE_MAP: {
					'MarketplaceNotDeliveredCostItem': 'возврат невостребованного товара от покупателя на склад.',
					'MarketplaceReturnAfterDeliveryCostItem': 'возврат от покупателя на склад после доставки.',
					'MarketplaceDeliveryCostItem': 'доставка товара до покупателя.',
					'MarketplaceSaleReviewsItem': 'приобретение отзывов на платформе.',
					'ItemAdvertisementForSupplierLogistic': 'доставка товаров на склад Ozon — кросс-докинг.',
					'MarketplaceServiceStorageItem': 'размещения товаров.',
					'MarketplaceMarketingActionCostItem': 'продвижение товаров.',
					'MarketplaceServiceItemInstallment': 'продвижениe и продажа в рассрочку.',
					'MarketplaceServiceItemMarkingItems': 'обязательная маркировка товаров.',
					'MarketplaceServiceItemFlexiblePaymentSchedule': 'гибкий график выплат.',
					'MarketplaceServiceItemReturnFromStock': 'комплектация товаров для вывоза продавцом.',
					'ItemAdvertisementForSupplierLogisticSeller': 'транспортно-экспедиционные услуги.',
					'MarketplaceServiceItemDelivToCustomer': 'последняя миля.',
					'MarketplaceServiceItemDirectFlowTrans': 'магистраль.',
					'MarketplaceServiceItemDropoffFF': 'обработка отправления.',
					'MarketplaceServiceItemDropoffPVZ': 'обработка отправления.',
					'MarketplaceServiceItemDropoffSC': 'обработка отправления.',
					'MarketplaceServiceItemFulfillment': 'сборка заказа.',
					'MarketplaceServiceItemPickup': 'выезд транспортного средства по адресу продавца для забора отправлений — Pick-up.',
					'MarketplaceServiceItemReturnAfterDelivToCustomer': 'обработка возврата.',
					'MarketplaceServiceItemReturnFlowTrans': 'обратная магистраль.',
					'MarketplaceServiceItemReturnNotDelivToCustomer': 'обработка отмен.',
					'MarketplaceServiceItemReturnPartGoodsCustomer': 'обработка невыкупа.',
					'MarketplaceRedistributionOfAcquiringOperation': 'оплата эквайринга',
					'MarketplaceServicePremiumCashback': 'услуги продвижения premium',
					'MarketplaceServicePremiumCashbackIndividualPoints': 'услуги продвижения бонусы продавца',
					'OperationMarketplaceWithHoldingForUndeliverableGoods': 'удержание за недовложение товара',
					'MarketplaceServiceItemReturnFlowLogistic': 'Доставка и обработка возврата, отмены, невыкупа',
					'MarketplaceServicePremiumPromotion': 'Услуга продвижения Premium',
					'MarketplaceReturnStorageServiceAtThePickupPointFbsItem': 'Краткосрочное размещение возврата FBS',
					'MarketplaceServiceDCFlowTrans': 'Магистраль распределительного центра',
					'MarketplaceServiceItemDirectFlowLogistic': 'Логистика',
					'MarketplaceServiceItemDirectFlowLogisticVDC': 'логистика в распределительный центр',
				},
				activeChangeCommentDay: null,
				commentDialog: false,
				form_comment: {
					text: null
				},
				dialog_detail_comission_fbo: false,
				detail_comission_fbo: [],
				dialog_detail_comission_fbs: false,
				detail_comission_fbs: [],
				loading_table: false,
				summaryDataPerDay: [],
				list_date: [],
				balancesLoading: false,
				costPriceDetailsDialog: false,
				activeCostPriceDetailsDay: null,
				activeShippingPriceDay: null,
				shippingPriceDialog: false,
				stocksWarehouseDialog: false,
				activeStocksWarehouseDay: null,
				chars_dialog: false,
				chars_data: {},
			};
		},
		computed: {
			dataRangeText() {
				return this.dates_range.join(' ~ ');
			},
			dateFromFormatted() {
				return this.date_after ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_after) :
					null;
			},
			dateToFormatted() {
				return this.date_before ?
					this.$options.filters.formatDateToLocaleNoTime(this.filters.date_before) :
					null;
			},
			data_test(){
				let list_data =  [
					{'label': 'empty', 'naming': " "},
					{'label': 'price', 'naming': "Цена", ...this.findField('price', ['premium_price', 'marketing_price', 'old_price', 'min_ozon_price', 'min_price', 'recommended_price', 'retail_price', 'marketing_seller_price', 'price_from_orders'])},
					{'label': 'price_promotions', 'naming': "Комиссия в акции", ...this.findField('sales_percent_fbo', ['new_promo_commission', 'promotions'])},
					{'label': 'spp_ozon', 'naming': "СПП", ...this.findField('spp_ozon')},
					{'label': 'price_indexes', 'naming': "Индексы", ...this.findField('general_index_type', ['external_index_minimum_price', 'external_index_value', 'ozon_index_minimum_price', 'ozon_index_value', 'other_index_minimum_price', 'other_index_value'])},
					{'label': 'localization_index_product', 'naming': "Индекс по товару", ...this.findField('localization_index_product')},
					{'label': 'comission_fbo', 'naming': "Комиссия и логистика FBO", ...this.findField('marketing_seller_price', ['sales_percent_fbo', 'fbo_direct_flow_trans_min_amount', 'fbo_deliv_to_customer_amount', 'fbo_return_flow_trans_min_amount', 'fbo_return_flow_amount', 'acquiring'])},
					{'label': 'comission_fbs', 'naming': "Комиссия и логистика FBS", ...this.findField('marketing_seller_price', ['sales_percent_fbs', 'fbs_direct_flow_trans_min_amount', 'fbs_direct_flow_trans_max_amount', 'fbs_deliv_to_customer_amount', 'fbs_return_flow_trans_min_amount', 'fbs_return_flow_trans_max_amount', 'fbs_return_flow_amount', 'acquiring'])},
					{'label': 'new_logistic', 'naming': "Новая логистика", ...this.findField('base_logistic', ['localization_index', 'min_logistic', 'max_logistic', 'current_logistic'])},
					{'label': 'volume_weight', 'naming': "Объем", ...this.findField('height', ['width', 'depth'])},
					{'label': 'cost_price', 'naming': "Себестоимость", ...this.findField('cost_price')},
					{'label': 'vp_plan', 'naming': "ВП прогноз", ...this.findField('vp_plan', ['sales_percent_fbo', 'fbo_direct_flow_trans_min_amount', 'marketing_seller_price', 'vp_plan_tax', 'tax_percent', 'current_logistic', 'orders_count_test', 'base_logistic', 'redemption_percent', 'price_from_orders'])},
					{'label': 'rentab', 'naming': "Рентабельность план", ...this.findField('rentab', ['orders_count_test'])},
					{'label': 'markup', 'naming': "Наценка план", ...this.findField('markup', ['orders_count_test'])},
					{'label': 'warehousing_expenses', 'naming': 'Расходы Склад/Логистика', ...this.findField('delivery_to_mp', ['acceptance', 'storage', 'assembling', 'packing', 'production_mi', 'cycles_year'])},
					{'label': 'warehousing_mp', 'naming': 'Расходы МП', ...this.findField('expenses_mp_sum', ['test_storage_mp', 'test_penalty', 'test_transit', 'test_other_expenses_mp'])},
					{'label': 'vp_mp', 'naming': 'ВП_МП, шт прогноз', ...this.findField('vp_mp', ['vp_ls', 'vp_m', 'orders_count_test'])},
					{'label': 'vp_mp_orders_plan', 'naming': 'ВП_МП итого прогноз', ...this.findField('vp_mp_orders_plan', ['orders_count_test'])},
					{'label': 'rentab_vp_mp', 'naming': 'Рентабельность ВП_МП', ...this.findField('rentab_vp_mp', ['rentab_vp_ls', 'rentab_vp_m', 'orders_count_test'])},
		
					{'label': 'empty', 'naming': 'Воронка продаж'},
					{'label': 'card_analytics', 'naming': 'Показы в поиске и каталоге', ...this.findField('views', ['views_delta'])},
                    {'label': 'card_analytics', 'naming': 'Показы на карточке товара', ...this.findField('open_card', ['open_card_delta'])},
                    {'label': 'card_analytics', 'naming': 'Положили в корзину, шт', ...this.findField('add_to_cart', ['add_to_cart_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'Конверсия в корзину', ...this.findField('cr_cart', ['cr_cart_delta'])},
                    {'label': 'card_analytics', 'naming': 'Заказали товаров, шт', ...this.findField('card_orders', ['card_orders_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'Конверсия в заказ', ...this.findField('cr_orders', ['cr_orders_delta'])},
                    {'label': 'card_analytics_per', 'naming': 'CR переход - заказ', ...this.findField('cr_open', ['cr_open_delta'])},
                    {'label': 'card_analytics_rub', 'naming': 'Заказали на сумму, р', ...this.findField('orders_sum_rub', ['orders_sum_rub_delta'])},

					{'label': 'empty', 'naming': "Заказы"},
					{'label': 'orders_api_amount', 'naming': "Заказы API руб", ...this.findField('orders_sum')},
					{'label': 'orders_api_count', 'naming': "Заказы API шт", ...this.findField('orders_count')},
					{'label': 'speed_orders', 'naming': "Скорость заказов, 14 дней", ...this.findField('orders_speed_14')},
					{'label': 'speed_orders_7', 'naming': 'Скорость заказов, 7 дней', ...this.findField('orders_speed_7')},
					{'label': 'vp_orders_plan', 'naming': "ВП с заказов план", ...this.findField('vp_orders_plan', ['orders_count_test'])},
					{'label': 'uvz', 'naming': "УВз", ...this.findField('lost_proceed_due_purchase')},
					{'label': 'uvo', 'naming': "УВо", ...this.findField('lost_proceed')},
					{'label': 'drr', 'naming': 'ДРР вн', ...this.findField('drr_adverts')},
                    {'label': 'drr_full', 'naming': 'ДРР', ...this.findField('drr_full')},

					{'label': 'empty', 'naming': "Выкупы"},
					{'label': 'redemption_sum', 'naming': "Сумма выкупов", ...this.findField('redemption_sum')},
					{'label': 'redemption_count', 'naming': "Кол-во выкупов", ...this.findField('redemption_count')},
					{'label': 'redemption_expenses', 'naming': "Расходы выкупы", ...this.findField('redemption_expenses', ['redemption_sum', 'sales_percent_fbo', 'fbo_direct_flow_trans_min_amount', 'current_logistic'])},
					{'label': 'distribution_sum', 'naming': "Сумма раздач", ...this.findField('distribution_sum')},
					{'label': 'distribution_count', 'naming': "Кол-во раздач", ...this.findField('distribution_count')},
					{'label': 'distribution_cashback', 'naming': "Расходы раздач", ...this.findField('distribution_cashback')},
					{'label': 'bloggers_expenses', 'naming': 'Расходы блогеры', ...this.findField('bloggers_expenses')},

					{'label': 'empty', 'naming': "Продажи"},
					{'label': 'sales_sum', 'naming': "Продажи руб", ...this.findField('sales_sum')},
					{'label': 'sales_count', 'naming': "Продажи шт", ...this.findField('sales_count')},
					{'label': 'sale_cost_price', 'naming': "Продажи по с/с руб", ...this.findField('sales_count', ['cost_price'])},
					{'label': 'vp_sale', 'naming': "ВП с продаж, руб", ...this.findField('vp_sale', ['vp_sale_new', 'sales_count', 'delivery_sum', 'comission_sum', 'other_sum', 'tax_rub', 'tax_percent'])},
					{'label': 'vp_sale_single', 'naming': "ВП 1шт, руб", ...this.findField('vp_sale_single', ['vp_sale_single_new', 'sales_sum', 'sales_count', 'cost_price'])},
					{'label': 'avg_vp_30_days', 'naming': "Средняя ВП (месяц)", ...this.findField('sales_sum_at_30_days', ['sales_count_at_30_days', 'avg_cost_price_at_30_days', 'avg_price_at_30_days', 'vp_at_30_days', 'avg_vp_at_30_days', 'avg_rentab_at_30_days', 'avg_markup_at_30_days'])},
					{'label': 'avg_vp_7_days', 'naming': "Средняя ВП (неделя)", ...this.findField('sales_sum_at_7_days', ['sales_count_at_7_days', 'avg_cost_price_at_7_days', 'avg_price_at_7_days', 'vp_at_7_days', 'avg_vp_at_7_days', 'avg_rentab_at_7_days', 'avg_markup_at_7_days'])},
					{'label': 'vp_cp', 'naming': 'ВП_ЧП', ...this.findField('vp_sale', ['vp_sale_new', 'sales_sum', 'redemption_expenses', 'distribution_cashback', 'expenses_other', 'expenses_search']),},
					{'label': 'transaction_expenses', 'naming': "Доля комиссий", ...this.findField('transactions_expenses', ['sales_sum'])},

					{'label': 'empty', 'naming': "Остатки"},
					{'label': 'stock_api', 'naming': "Остатки API", ...this.findField('all_stock_present', ['stock_present_fbs', 'stock_present_fbo', 'all_stock_reserved', 'stock_reserved_fbs', 'stock_reserved_fbo', 'coming'])},
					{'label': 'stock_1c', 'naming': "Остатки 1С", ...this.findField('catalog_count', ['kit_count', 'kit_main_count'])},
					{'label': 'warenwert_mp', 'naming': "Стоимость товара на МП", ...this.findField('warenwert_mp')},
					{'label': 'warenwert_1c', 'naming': "Стоимость товара 1С", ...this.findField('cost_price', ['catalog_count', 'kit_main_count'])},
					{'label': 'warehouse_expenses', 'naming': "Расходы по складу",  ...this.findField('shipping_result')},			
					{'label': 'storage_cost', 'naming': "Стоимость хранения за день",  ...this.findField('storage_cost')},				
					
					{'label': 'empty', 'naming': "Реклама в поиске"},
					{'label': 'expenses_search', 'naming': "Расход", ...this.findField('expenses_search')},
					{'label': 'orders_search', 'naming': "Заказы", ...this.findField('orders_search')},
					{'label': 'bet_search', 'naming': "Ср. Ставка", ...this.findField('bet_search')},

					{'label': 'empty', 'naming': "Реклама в брендовой полке"},
					{'label': 'expenses_brand', 'naming': "Расход", ...this.findField('expenses_brand')},
					{'label': 'orders_brand', 'naming': "Заказы", ...this.findField('orders_brand')},
				
					{'label': 'empty', 'naming': "Другая реклама"},
					{'label': 'expenses_other', 'naming': "Расход", ...this.findField('expenses_other')},
					{'label': 'orders_other', 'naming': "Заказы", ...this.findField('orders_other')},
					{'label': 'bet_other', 'naming': "Ср. ставка", ...this.findField('bet_other')},
					{'label': 'adver_views', 'naming': "Просмотры", ...this.findField('adver_views')},
					{'label': 'adver_click', 'naming': "Клики", ...this.findField('adver_click')},
					{'label': 'click_cost', 'naming': "Цена за клик", ...this.findField('expenses_other', ['adver_click'])},
				]
				return list_data
			},
			keys(){
				let key = [
					{title: " ", key: "naming", fixed:"left", width: 170, slot: 'head'}
				]
				this.list_date.forEach(element => {
					key.push(
						{
							title: element,
							key: element,
							width: 120, 
							resizable: true,
							align: "center",
							slot: 'data',
							headSlot: 'column_name',
							comment: this.getCommentDate(element)
						}
					)
				})
				return key
            },
		},
		async mounted() {
			await this.fetchProductDataDays();
		},
		methods: {
			async openDialogChars(date){
				this.chars_data = {}
				try{
					const response = await Products.fetchOzonProductChars(this.product_id, date)
					this.chars_data = response.data
				} catch(err){
					console.log(err)
				}
				this.chars_dialog = true
			},
			calc_price_with_promotion(promotions, min_price){
				let price = 0
				if (promotions && promotions.length > 0){
					promotions.forEach(item=>{
						if (min_price && min_price > 0){
							if (item.discount_value >= min_price)
								price = item.discount_value
						}
						else{
							price = item.discount_value
						}
					})
					return price
				}
				return
			},
			async finishUpdatePrice() {
				this.changePriceDialog = false;
				await this.fetchProductDataDays();
			},
			sumTransactionExpenses(array){
				return array.reduce((accum,item) => accum + item.sum_expenses, 0)
			},
			convert_chars_value(value){
				return value.length > 1 ? value.join(','): value[0]
			},
			getCommentDate(date){
				let filter_data = this.summaryDataPerDay.filter(item => item.date == date);
				if (filter_data && filter_data.length > 0){
					return filter_data[0]?.comment
				}
				return ''
			},
			emptyCell(label){
				if (label == 'empty')
					return true
				return false
			},
			findField(field, kwargs=[]){
				let result = {}
				this.list_date.forEach(el => {
					let filter_data = this.summaryDataPerDay.filter(item => item.date == el);
					try{
						if (kwargs.length == 0)
							result[el] = filter_data[0][field]
						else{
							result[el] = [filter_data[0][field]]
							kwargs.forEach(el_kw=>{
								result[el].push(filter_data[0][el_kw])
								
							})
						}
							
					} catch{
						if (kwargs.length == 0)
							result[el] = '-'
						else{
							result[el] = ['-']
							kwargs.forEach(el_kw=>{
								result[el].push('-')
								
							})
						}
					}
				})
				return result
			},
			async fetchProductDataDays() {
				this.balancesLoading = true;
				try {
					let query = {
						"code_1c": this.catalog_item_id,
						"dates_range": this.dates_range ? this.dates_range.join(',') : null
					}
					const res = await Products.fetchOzonProductDataDays(this.product_id, query)
					this.summaryDataPerDay = res.data.data;
					this.list_date = res.data.list_date
				} catch (err) {
					console.log(err);
				}
				this.balancesLoading = false;
			},
			openCostPriceDetailsDialog(date) {
				this.activeCostPriceDetailsDay = date;
				this.costPriceDetailsDialog = true;
			},
			openStocksWarehouseDialog(date){
				this.activeStocksWarehouseDay = date;
				this.stocksWarehouseDialog = true
			},
			sumShippingPacks(array){
				return array.reduce((accum,item) => accum + item.sum_cost_one, 0)
			},
			openShippingPriceDialog(date){
				this.activeShippingPriceDay = date;
				this.shippingPriceDialog = true;
			},
			openDiagComissionFbo(data){
				this.dialog_detail_comission_fbo = true,
				this.detail_comission_fbo = data
			},
			openDiagComissionFbs(data){
				this.dialog_detail_comission_fbs = true,
				this.detail_comission_fbs = data
			},
			openDialogComment(date) {
				this.activeChangeCommentDay = date;
				this.form_comment.text = this.getCommentDate(date)
				this.commentDialog = true;
			},
			async saveComment(del=false){
				let text = !del ? this.form_comment.text : ''
				const data = {
					"product": this.product_id,
					"date": this.activeChangeCommentDay,
					"text": text
				}  
				try{
					const response = await Products.saveOzonComment(data)
					this.fetchProductDataDays()

				} catch (err){
					console.log(err)
				}   
				this.commentDialog = false;
				this.activeChangeCommentDay = null;
			},
			getTransactionExpensesName(name){
				if (this.SERVICE_MAP_LOCALE_MAP[name])
					return this.SERVICE_MAP_LOCALE_MAP[name]
				return name
			},
			calculatePercent(val_1, val_2){
				if (!val_1 || !val_2)
					return 0
				return val_1 / val_2 * 100
			},
			calcHelpText(label){
				if (label == 'vp_ls')
					return 'ВП план - Расходы склада/логистика'
                if (label == 'vp_m')
                    return 'Плановые рекламные расходы'
                if (label == 'vp_mp')
                    return 'Плановые расходы МП'
				return ''
			}
		},
	};
</script>

<style>
	.no_wrap{
		white-space: nowrap;
	}
	.green-index {
        background-color: #d9ead3 !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
		display: block;
    }
	.yellow-index {
        background-color: #fff2cc !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
		display: block;
    }
	.red-index {
        background-color: #f1b7bd !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
		display: block;
    }
	.without-index {
        background-color: #e7e5e5 !important;
        width: 100%;
        height: 100%;
        padding: 0 5px;
		display: block;
    }
	.green_delta{
        color: #067b00;
    }
    .red_delta{
        color: #f96666;
    }
    .bold_card_analytics{
        font-weight: bold;
	}
</style>