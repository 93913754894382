<template>
	<v-data-table :headers="headers" :items="products" hide-default-footer dense>
        <template v-slot:item.image="{ item }">
            <div class="d-flex align-center justify-center py-1">
                <img style="max-height: 50px" :src="item.child_product.image" alt="Фото удалено"/>
            </div>
        </template>
        <template v-slot:item.nmid="{ item }">
            <router-link 
                class='dashed' target="_blank" :to="{name: 'product_card', params: { id: item.child_product.nmid }}"
            >{{ item.child_product.nmid }}</router-link>
        </template>
    </v-data-table>
</template>

<script>

export default {
	name: 'DialogAdvertisementDetailProduct',
	props: ['products'],
    data(){
        return {
            headers: [
                { text: "Изображение", value: "image", sortable: false, width: "1%", align: 'center' },
                { text: "Артикул", value: "nmid", sortable: false, width: "1%", align: 'center' },
                { text: "Название", value: "child_product.name", sortable: false, width: "1%", align: 'center' },
                { text: "Артикул 1С", value: "child_product.code_1c", sortable: false, width: "1%", align: 'center' },
            ],
        }
    },
}
</script>