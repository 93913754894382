<template>
	<div>
		<v-dialog v-model="charts_dialog" max-width="100%" v-if="charts_dialog">
			<v-card>
				<v-card-title>График показов кампании {{ charts_item }}</v-card-title>
				<v-card-text class="mt-5">
					<AdvertisementListChartsComponent :campaign_id="charts_item"/>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-data-table
			:headers="table.headers"
			:items="table.data"
			class="elevation-2 advertisement_list"
			:loading="table.loading"
			color="green"
			:options.sync="table.options"
			:items-per-page="50"
			:footer-props="{'items-per-page-options': [10, 15, 25, 50]}"
			:server-items-length="table.pagination.totalItems"
			height="600px"
			:fixed-header="true"
		>
			<template v-slot:item.banner="{ item }">
				<div class="d-flex justify-center py-2">
					<v-img
						v-for="(i, index) in get_image_list(item)" :key="index" 
						:src="i" contain max-height="70px" max-width="70px"
					/>
				</div>
			</template>
			<template v-slot:item.nmid="{ item }">
				<div class="d-flex justify-center flex-column align-center">
					<div v-for="el in item.advertisement_products" :key="el.child_product.nmid">
						<router-link v-if="!el.is_fake" target="_blank" :to="{name: 'product_card', params: { id: el.child_product.nmid }}">
							{{ el.child_product.code_1c }}
						</router-link>
					</div>
				</div>
			</template>
			<template v-slot:item.campaign_id="{ item }">
				<a target="_blank" :href="get_wb_link_to_adver(item)">{{item.campaign_id}}</a>
			</template>
			<template v-slot:item.campaign_name="{ item }">
				<router-link target="_blank" :to="{name: 'advertisement_detail', params: { campaign_id: item.campaign_id }}">
					{{ item.campaign_name }}
				</router-link>
			</template>
			<template v-slot:item.campaign_status="{ item }">
				<v-chip 
					class="ma-2" :color="$vuetify.theme.dark ? '#4f7844' : '#c0f1b4'"  dense
					v-if="item.campaign_status == 'ACTIVE'"
				>{{ item.campaign_status_display }}</v-chip>
				<v-chip 
					class="ma-2" dense :color="$vuetify.theme.dark ? '#cb7029' : '#ffbd67'"
					v-else-if="item.campaign_status == 'PAUSE'"
				>{{ item.campaign_status_display }}</v-chip>
				<v-chip 
					class="ma-2" :color="$vuetify.theme.dark ? 'blue-grey darken-1' : 'blue-grey lighten-3'" dense
					v-else
				>{{ item.campaign_status_display }}</v-chip>
			</template>
			<template v-slot:item.cpm="{ item }">
				<div>{{ item.cpm | toRUB }} ({{ item.budget | toRUB }})</div>
			</template>
			<template v-slot:item.profit="{ item }">
				<div style="white-space: nowrap;" :class="{
					'green-recommended-bet': item.profit >= 0, 'red-recommended-bet': item.profit < 0
				}">{{ item.profit ? item.profit : 0 | toRUB }}</div>
				<v-divider></v-divider>
				<div style="white-space: nowrap;">{{ item.drr ? item.drr.toFixed(2): 0 }}%</div>		
			</template>
			<template v-slot:item.views="{ item }">
				<div style="white-space: nowrap;" class="d-flex align-center justify-center">
					<div>{{ item.views ? item.views : 0 }}</div>
					<v-btn icon class="ml-2" @click="open_charts_dialog(item)">
						<v-icon small>mdi-chart-bell-curve-cumulative</v-icon>
					</v-btn>
				</div>
			</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex justify-center align-center">
					<v-switch 
						v-model="item.is_active" :color="$vuetify.theme.dark ? '#559843' : '#559843'" 
						disabled class="mt-0" hide-details v-if="item.campaign_status == 'STOP'"
					></v-switch>
					<v-switch 
						v-model="item.is_active" :color="$vuetify.theme.dark ? '#559843' : '#559843'" 
						loading="warning" class="mt-0" hide-details @change="change_status(item)" 
						v-else-if="loading_change_status == item.campaign_id"
					></v-switch>
					<v-switch 
						v-model="item.is_active" :color="$vuetify.theme.dark ? '#559843' : '#559843'" 
						class="mt-0" hide-details @change="change_status(item)" v-else
					></v-switch>
				</div>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { Advertisement } from '@/api/advertisement'
import AdvertisementListChartsComponent from '@/components/Advertisement/AdvertisementList/AdvertisementListChartsComponent'
export default {
    name: 'AdvertisementListTableComponent',
	components: { AdvertisementListChartsComponent },
    data() {
        return {
			table:{
				first_init: false,
				loading: false,
				data: [],
				headers: [
					{ text: 'Фото', value: 'banner', sortable: false, width: '1%', align: 'center' },
					{ text: 'Артикул', value: 'nmid', sortable: false, width: '1%', align: 'center' },
					{ text: 'Тип', value: 'campaign_type', sortable: false , width: '1%', align: 'center' },
					{ text: 'ID', value: 'campaign_id', sortable: true , width: '1%', align: 'center' },
					{ text: 'Кампания', value: 'campaign_name', sortable: true , width: '1%', align: 'center' },
					{ text: 'Статус', value: 'campaign_status', sortable: true , width: '1%', align: 'center' },
					{ text: 'Ставка (бюджет)', value: 'cpm', sortable: false , width: '1%', align: 'center' },
					{ text: 'Доход / ДРР', value: 'profit', sortable: true, width: '1%', align: 'center' },
					{ text: 'Показы кампании', value: 'views', sortable: false, width: '1%', align: 'center' },
					{ text: 'вкл/выкл', value: 'action', sortable: false, width: '1%', align: 'center' },
				],
				options: {},
				pagination: {
					next: null,
					totalItems: 0,
					numberOfPages: 0,
					currentPage: 0
				},
			},
			charts_dialog: false,
			charts_item: null,
			loading_change_status: null
		}		
    },
	props: ['filters'],
    watch:{
		async filters(){
			this.table.options.page = 1
            await this.get_advertisement_list()
		},
        'table.options.sortDesc': async function (newVal, oldVal) {
			if (oldVal && this.table.first_init) {
				await this.get_advertisement_list()
			}
        },
	},
    methods: {
		async get_advertisement_list(){
            this.table.loading = true
            const { page, itemsPerPage, sortDesc, sortBy} = this.table.options
            try {
				let pageNumber = page - 1
				if (this.table.data.length != 0)
					window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                let response = await Advertisement.fetchAdvertisementList({
					...this.filters, 'offset': itemsPerPage * pageNumber, 
					'limit': itemsPerPage, 'sort': sortDesc[0] ? `-${sortBy[0]}` : sortBy[0]
				})	
                this.table.data = response.data.results
                this.table.pagination.totalItems = response.data.count
                this.table.pagination.numberOfPages = Math.ceil(response.data.count / itemsPerPage)
            } catch (err) {
                console.error(err.response)
            }
            this.table.loading = false
			if (!this.table.first_init)
				this.table.first_init = true
        },
		async change_status(item){
			this.loading_change_status = item.campaign_id
			let tmp_data = this.table.data
			try{
				const response = await Advertisement.changeAdvertisementStatus(item.campaign_id)
				this.$toasted.show(`Статус успешно изменен`, {
					theme: 'bubble', type: 'success', position: 'top-center', duration: 3000,
				})
				tmp_data[tmp_data.indexOf(item)] = response.data
			} catch(err){
				this.$toasted.show(`Ошибка(${err.response.data.error})`, {
					theme: 'bubble', type: 'error', position: 'top-center', duration: 3000,
				});
				tmp_data[tmp_data.indexOf(item)]['is_active'] = !item.is_active
			}
			this.table.data = []
			this.table.data.push(...tmp_data)
			this.loading_change_status = null
		},
		async open_charts_dialog(item){
			this.charts_dialog = true
			this.charts_item = item.campaign_id
		},
		get_wb_link_to_adver(item){
			return `https://cmp.wildberries.ru/campaigns/edit/${item.campaign_id}`
		},
		get_image_list(item){
			let products = item.advertisement_products
			if (products.length < 1)
				return []
			let images = []
			products.forEach(element => {
				if (!element.is_fake)
					images.push(element.child_product.image)
			})
			return images.slice(0, 3)
		},
    },
}
</script>
<style lang="scss">  
	.theme--dark tbody {
		tr:hover {
			background-color: #1a1a1a !important;
		}
	}
</style>

<style lang="scss" scoped>
	.red-recommended-bet{
        background-color: #f1b7bd;
    }
	.theme--dark .red-recommended-bet{
		background-color: #a0535b;
	}
    .green-recommended-bet{
        background-color: #c0f1b4;
    }
	.theme--dark .green-recommended-bet{
		background-color: #4f7844;
	}
</style>
<style lang="scss">
	.theme--light .advertisement_list .v-progress-linear__background{
        background-color: #20ddca !important;
		border-color: #20ddca !important;
    }
	.theme--dark .advertisement_list .v-progress-linear__background{
        background-color: #208379 !important;
		border-color: #208379 !important;
    }
	.theme--light .advertisement_list .v-progress-linear__indeterminate{
        background-color: #20ddca !important;
		border-color: #20ddca !important;
    }
	
	.theme--dark .advertisement_list .v-progress-linear__indeterminate{
        background-color: #208379 !important;
		border-color: #208379 !important;
    }
	
</style>