import { HTTP } from './http'

export const ManagementProduct = {
	async createTemplateManagement (query=null) {
		return HTTP.post('/management_product/create_template/', query)
	},
	async fetchListTemplateManagement (query=null) {
		return HTTP.get('/management_product/templates_management/', {params: query})
	},
	async deleteTemplateManagement (id) {
		return HTTP.post(`/management_product/delete_template/${id}/`)
	},
	async fetchDetailTemplateManagement (id) {
		return HTTP.get(`/management_product/templates_management/${id}/`)
	},
	async saveLastStateTemplate(id, json_data){
		return HTTP.post(`/management_product/save_last_state_template/${id}/`, {"state": json_data})
	},
	async cleanLastStateTemplate(id){
		return HTTP.post(`/management_product/clean_last_state_template/${id}/`)
	},
	async fetchWbAccountByTemplates(template_id){
		return HTTP.get(`/management_product/wbaccount_by_templates/${template_id}/`)
	},
	async createPromotion(id, query){
		return HTTP.post(`/management_product/create_promotion/${id}/`, query)
	},
	async changeAutoUpdate(id){
		return HTTP.post(`/management_product/change_template_auto_update/${id}/`)
	},
	async changeIsOpen(id){
		return HTTP.post(`/management_product/change_template_open/${id}/`)
	},
	async changeOzonIsOpen(id){
		return HTTP.post(`/management_product/ozon/templates/${id}/change_is_open/`)
	},
	async deletePromotion(id){
		return HTTP.post(`/management_product/delete_promotion/${id}/`)
	},
	async checkPriceDiscountForUpdate(product_data){
		return HTTP.post('/management_product/check_price_discount_for_update/', {
			"products": product_data
		})
	},
	async updatePriceDiscount(product_data){
		return HTTP.post('/management_product/update_price_discount_by_product/', {
			"products": product_data
		})
	},
	async fetchDelayedTaskManagement (query=null) {
		return HTTP.get('/management_product/list_delayed_task/', {params: query})
	},
	async createDelayedTask(data){
		return HTTP.post('/management_product/create_delayed_task/', data)
	},
	async getProductsByTemplate(id){
		return HTTP.get(`/management_product/get_products_by_tempate/${id}/`)
	},
	async updateTemplateProduct(id, data){
		return HTTP.post(`/management_product/update_template_products/${id}/`, data)
	},
	async getCompetitorsPrice(nmid){
		return HTTP.get(`/management_product/get_competitors_price_by_date/`, {params: {"nmid": nmid}})
	},
	async SearchPromotion(search){
		return HTTP.get(`/management_product/search_promotion/`, {params: {"search": search}})
	},
	async selectPromotionFromOutside(data){
		return HTTP.post(`/management_product/select_promotion/`, data)
	},
	async updatePromotion(query){
		return HTTP.post(`/management_product/upload_file_to_promotion/`, query)
	},
	async copyPromotion(query){
		return HTTP.post(`/management_product/copy_promotion_to_other_accounts/`, query)
	},
	async addExistPromotionToTemplate(data){
		return HTTP.post(`/management_product/add_wb_promotion_to_template/`, data)
	},
	async fetchExistPromotion(query=null){
		return HTTP.get(`/management_product/get_exists_wb_promotion/`, {params: query})
	},
	async exportToGoogle (id, data) {
		return HTTP.post(`/management_product/templates_management/${id}/`, data)
	},
	async fetchOzonListTemplates(query=null){
		return HTTP.get('/management_product/ozon/templates/', {params: query})
	},
	async fetchOzonDetailTemplates(id){
		return HTTP.get(`/management_product/ozon/templates/${id}/`)
	},
	async deleteOzonTemplates(id){
		return HTTP.delete(`/management_product/ozon/templates/${id}/`)
	},
	async createOzonTemplates(data){
		return HTTP.post(`/management_product/ozon/templates/`, data)
	},
	async exportOzonTemplates (id, data) {
		return HTTP.post(`/management_product/ozon/templates/${id}/export/`, data)
	},
	async saveStateOzonTemplates(id, state){
		return HTTP.post(`/management_product/ozon/templates/${id}/save_last_state/`, state)
	},
	async clearStateOzonTemplates(id){
		return HTTP.post(`/management_product/ozon/templates/${id}/clear_last_state/`)
	},
	async autoUpdateOzonTemplates(id){
		return HTTP.post(`/management_product/ozon/templates/${id}/change_auto_update_template/`)
	},
	async productsOzonTemplates(id){
		return HTTP.get(`/management_product/ozon/templates/${id}/template_products/`)
	},
	async changeOzonTemplate(id, data){
		return HTTP.post(`/management_product/ozon/templates/${id}/save_template_products/`, data)
	},
}
